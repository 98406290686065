<template>
  <v-card class="flex py-0" tile>
    <v-col cols="12" align="center"
      >
      <v-col cols="2">
      <a href="https://www.ciafj.ch" target="_blank">
        Site officiel de la CIAFJ
      </a>
      </v-col>
    </v-col>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    icons: ['mdi-facebook-box', 'mdi-twitter-box', 'mdi-instagram']
  })
}
</script>

<style lang="scss">
.v-list-item {
  min-height: 25px;
}
a{
  color:#494A75!important;
}
</style>
